import React from 'react';

import styles from './Loading.module.scss';

export default function Loading() {
    const text = "ROBERTO & FIGUEREDO ADVOCACIA";
    return (
        <div className={styles['loading-container']}>
            <svg width="500" height="100" viewBox="0 0 500 100" xmlns="http://www.w3.org/2000/svg">
                {text.split('').map((letter, index) => (
                    <text 
                        key={index} 
                        x={index * 30} 
                        y="50" 
                        className={styles['loading-container__letter']} 
                        style={{ animationDelay: `${index * 0.2}s` }}
                    >
                        {letter}
                    </text>
                ))}
            </svg>
        </div>
    );
}
